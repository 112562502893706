@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body {
  font-family: "Rubik", sans-serif !important;
}

.main-logo {
  height: 50px;
}

.home-image-grid img {
  width: 100% !important;
  height: 100%;
}

.main-navbar-bg {
  background: #1a202c;
  color: white;
}

.navlink {
  position: relative;
  /* Needed for pseudo-element positioning */
  text-decoration: none;
  /* Remove default underline */
}

.navlink:before,
.navlink:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
  /* Replace with desired color */
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.3s ease;
  /* Animate opacity on hover/active */
}

.navlink:hover:before,
.navlink.active:before {
  opacity: 1;
  /* Show underline on hover/active */
}


.swiper {
  width: 100%;
  height: 100%;
  background: #000;
}

.swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}

.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 75vh !important;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}

.swiper-slide .subtitle {
  font-size: 21px;
}

.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}

.main-slide-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-slide-content .title {
  font-size: 28px;
  font-weight: 700;
}

.main-slide-content .text {
  width: 90vw;
}

.main-slide-content .text p {
  width: 50vw !important;
  font-size: 52px;
  font-weight: 700;
}


.creative-showcase--slider .creative-button--wrapper .swiper-button-prev,
.creative-showcase--slider .creative-button--wrapper .swiper-button-next {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: auto;
  color: #fff;
  z-index: 9;
  cursor: pointer;
  width: auto;
  padding-inline: 5px;
  bottom: 30px;
  border: .5px solid #ffffff69;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: normal;
}

.creative-showcase--slider .creative-button--wrapper .swiper-button-next {
  right: 120px;
}

.creative-showcase--slider .creative-button--wrapper .swiper-button-prev {
  left: 120px;
}

.creative-showcase--slider .creative-button--wrapper .swiper-button-next:after,
.creative-showcase--slider .creative-button--wrapper .swiper-button-prev:after {
  display: none;
}

.creative-showcase--slider .creative-button--wrapper .swiper-button-prev i {
  margin-right: 25px;
}

.creative-showcase--slider .creative-button--wrapper .swiper-button-next i {
  margin-left: 25px;
}

@media screen and (max-width:622px) {

  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}

.faq-bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../src/images/faq/faqbg.png);
  background-size: cover;
  background-position: center;
  height: auto;
}

.social-link {
  font-size: 30px;
  color: white;
  padding: 5px;
  transition: .5s all;
}

.social-link:hover {

  border-radius: 50%;
  scale: 1.1;
  background-color: white;
  color: #1a202c;
  transition: .5s all;
}

.whatsapp-link {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 10px;
  right: 10px;
  left: unset;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-filter {
  border: 1px solid #1a202c;
  padding-inline: 20px;
  padding-block: 6px;
  border-radius: 20px;
  cursor: pointer;
  transition: .5s all;
  white-space: nowrap;
}

.category-filter-active {
  border: 1px solid #1a202c;
  transition: .5s all;
  cursor: pointer;
  background-color: #1a202c;
  color: white;
  padding-inline: 20px;
  padding-block: 6px;
  border-radius: 20px;
  white-space: nowrap;
}



/* Mobile-specific styles for navigation links */
@media (max-width: 767px) {
  .navlink {
    display: block;
    padding: 1rem 1.5rem;
    color: #ffffff;
    text-decoration: none;
    border-radius: 0.375rem; /* rounded-md */
    transition: background-color 0.3s ease;
  }

  .navlink:hover,
  .navlink:focus {
    background-color: #4b5563; /* Darker shade on hover or focus */
  }

  /* Active state styles */
  .side-drawer .navlink.active {
    background-color: #1f2937; /* Highlighted background for active link */
  }

  /* Side Drawer styles */
  .side-drawer .navlink {
    padding: 1rem;
  }

  /* Optional: Specific styles for mobile drawer */
  .side-drawer {
    position: fixed;
    inset-y: 0;
    left: 0;
    width: 64px; /* Adjust width as needed */
    background-color: #1f2937; /* Dark background for drawer */
    color: #ffffff;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 1501;
  }

  .side-drawer.open {
    transform: translateX(0);
  }

  .drawer-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(31, 41, 55, 0.75); /* Semi-transparent background */
    transition: opacity 0.3s ease;
    opacity: 0;
    pointer-events: none;
    z-index: 1500;
  }

  .drawer-overlay.open {
    opacity: 1;
    pointer-events: auto;
  }
}

/* Hide mobile drawer on larger screens */
@media (min-width: 768px) {
  .side-drawer {
    display: none;
  }
}

.scroll-to-top {
  position: fixed;
  bottom: 70px;
  right: 20px;
  font-size: 4rem;
  color: #1f2937;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 10px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
}

.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
  z-index: 1500;
}

.scroll-to-top.hide {
  opacity: 0;
  visibility: hidden;
}
.truncate-3-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
}


.image-container {
  width: 100%;
  height: 200px; /* Set a fixed height */
  overflow: hidden; /* Hide overflow to maintain aspect ratio */
  position: relative;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Scale the image to cover the container */
}

.filtered-product{
  background-color: #fff;
  box-shadow: 0px 0px 10px #5563a033;
}

.main-product-icon{
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 10px;
}
.main-product-icon .icon{
  background-color: #1a202c;
  color: white;
  font-size: 28px;
  padding: 10px;
  border-radius: 50%;
}