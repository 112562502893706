.swiper-container {
    height: 100%;
    max-height: 100vw;
    min-height: 0;
    min-width: 0;
    width: 100%;
    overflow: hidden;
}

.swiper-slide.product {
    width: 100%;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
    padding: 0 !important;
}

.swiper-wrapper {
    max-height: 100%;
    height: 100%;
    display: flex;
    border-radius: 20px;
}
.main-full-swiper,.main-full-swiper *{
    border-radius: 20px;
    background-color: transparent !important;
}
.main-full-swiper{
    background-color: transparent !important;
}
.mini-full-swiper .swiper-wrapper {
    justify-content: center;
}

.main-swiper {
    width: 100%;
    height: 100%;
}

.thumbs-swiper {
    width: 100%;
    margin-top: 10px !important;
    background-color: transparent !important;
}

.thumbs-swiper .swiper-slide {
    height: auto;
    opacity: 0.4;
    transition: opacity 0.3s;
}

.swiper-slide.mini-swiper {
    width: 80px !important;
    padding: 0px !important;
}

.thumbs-swiper .swiper-slide-thumb-active {
    opacity: 1;
}

@media (max-width: 768px) {
    /* .main-swiper {
        height: 300px;
    } */

    .thumbs-swiper .swiper-slide {
        width: 33.33%;
    }
}

@media (max-width: 480px) {
    /* .main-swiper {
        height: 200px;
    } */

    .thumbs-swiper .swiper-slide {
        width: 50%;
    }
}

button {
    transition: transform 0.2s ease-in-out;
}

button:hover {
    transform: translateY(-2px);
}

.swiper-slide {
    width: auto;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
}